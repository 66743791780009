import React, { useContext } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"

import FileThumbnail from "./FileThumbnail"
import FilePreview from "../../Upload/FilePreview"

import {
  formatPrice,
  accumulatePrice,
  generateDeliveryFee,
} from "../services/computations"
import styles from "../utils/epharmacy.module.scss"
import { AppContext } from "../../../context/AppContext"

const PatientOrderInfo = ({ label, value, className }) => (
  <p
    className={classNames(
      "is-size-6",
      className || "",
      styles["specialOrder__patient_infoMobile"]
    )}
  >
    <span>{label}</span>
    <span>{value}</span>
  </p>
)

const PatientListRowMobile = ({ patient, index }) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy
  let oldFileNameArray = patient?.documents[0]?.oldname?.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  const handleRemovePatient = ({ index }) => {
    const patientList = [...patients]
    patientList.splice(index, 1)
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          patients: [...patientList],
        },
      },
    })
  }

  const handleEditPatient = ({ index }) => {
    dispatch(
      {
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          documents: patients[index]?.documents || [],
          epharmacy: {
            ...state.epharmacy,
            medicines: patients[index]?.medicines || [],
          },
        },
      },
      navigate("/special-order/input-patient", {
        state: {
          ...patients[index],
          index: index,
        },
      })
    )
  }

  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(patient?.medicines) + generateDeliveryFee()
    ).toFixed(2),
  })

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: (
          <FilePreview
            file={patient?.documents[0]}
            isPdf={fileType === "pdf"}
          />
        ),
      },
    })

  return (
    <div
      className={classNames(
        "card mb-2 mt-1",
        styles["specialOrder__patient_card"]
      )}
    >
      <div className="card-content">
        <p className="is-size-6">
          Patient:{" "}
          <span className="has-text-weight-bold">{patient?.patientName}</span>
        </p>
        <hr className="has-background-light mt-1" />
        <div className="columns is-vcentered is-mobile">
          <div className="column is-3" onClick={handleFileClick}>
            <FileThumbnail isMobile path={patient?.documents[0]?.path} />
          </div>
          <div className="column">
            <PatientOrderInfo
              label="Total"
              value={`P${orderSubtotal}`}
              className="has-text-weight-bold"
            />
            <p className="has-text-right">
              <span
                className="has-text-primary has-text-weight-bold is-size-6 mr-1"
                onClick={() => handleEditPatient({ index })}
              >
                Edit
              </span>
              |
              <span
                className="has-text-danger has-text-weight-bold is-size-6 ml-1"
                onClick={() => handleRemovePatient({ index })}
              >
                Remove
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientListRowMobile
