import React, { useContext } from "react"
import PatientListRowMobile from "./PatientListRowMobile"

import { AppContext } from "../../../context/AppContext"

const PatientListMobile = () => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy
  return (
    <div className="is-hidden-tablet is-hidden-desktop">
      {patients.map((patient, index) => (
        <PatientListRowMobile patient={patient} index={index} />
      ))}
    </div>
  )
}

export default PatientListMobile
