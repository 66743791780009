import React, { useContext, Fragment } from "react"
import { Link } from "gatsby"

import NoPatientsMessage, {
  NoPatientsMessageMobile,
} from "./SpecialOrder/NoPatientsMessage"
import ActionButtons from "../Elements/ActionButtons"
import PatientListTable from "./SpecialOrder/PatientListTable"
import PatientListMobile from "./SpecialOrder/PatientListMobile"

import { AppContext } from "../../context/AppContext"

const SpecialOrder = () => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy

  return (
    <Fragment>
      <div className="mb-5">
        <div className="buttons is-right mb-0">
          <Link className="button is-primary" to="/special-order/input-patient">
            + Add New Patient
          </Link>
        </div>
        <PatientListTable />
        <PatientListMobile />
        <NoPatientsMessageMobile patients={patients} />
        <NoPatientsMessage patients={patients} />
      </div>
      <ActionButtons
        next={{
          label: "Checkout",
          link: "/special-order/delivery-details",
          disabled: patients?.length === 0,
        }}
      />
    </Fragment>
  )
}

export default SpecialOrder
