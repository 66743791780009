import React, { useContext } from "react"

import Table from "../../Elements/Table"
import PatientListTableRow from "./PatientListTableRow"

import { AppContext } from "../../../context/AppContext"

const PatientListTable = () => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy

  return (
    <Table
      headers={[
        { data: "Patient" },
        { data: "Prescription" },
        { data: "Order Total", className: "has-text-right" },
        { data: "" },
      ]}
      className="mb-1 is-hidden-mobile"
    >
      {patients?.map((patient, index) => (
        <PatientListTableRow patient={patient} index={index} />
      ))}
    </Table>
  )
}

export default PatientListTable
