import React, { useContext } from "react"
import { navigate } from "gatsby"

import { AppContext } from "../../../context/AppContext"

const PatientListButtons = ({ index, handleRemovePatient }) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy

  const handleEditPatient = ({ index }) => {
    dispatch(
      {
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          documents: patients[index]?.documents || [],
          epharmacy: {
            ...state.epharmacy,
            medicines: patients[index]?.medicines || [],
          },
        },
      },
      navigate("/special-order/input-patient", {
        state: {
          ...patients[index],
          index: index,
        },
      })
    )
  }

  return (
    <div className="buttons is-right">
      <button
        type="button"
        className="button is-small is-light has-text-primary"
        onClick={() => handleEditPatient({ index })}
      >
        Edit
      </button>
      <button
        type="button"
        className="button is-small is-danger"
        onClick={() => handleRemovePatient({ index })}
      >
        Remove
      </button>
    </div>
  )
}

export default PatientListButtons
