import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import SpecialOrder from "../../components/Epharmacy/SpecialOrder"

export default ({ location }) => (
  <Layout
    title="Order Medicines"
    subtitle={
      <span>
        Click '<span className="has-text-primary">Add New Patient</span>' to
        place an order for each patient.
      </span>
    }
    process="epharmacy"
    seoTitle="Order Medicines"
    display={{ footer: false, helpCenterBanner: false }}
    path={location?.pathname}
    underMaintenance={
      process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"
    }
  >
    <Container isCentered desktop={8} fullhd={8}>
      <SpecialOrder />
    </Container>
  </Layout>
)
