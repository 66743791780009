import React, { useContext } from "react"

import FileThumbnail from "./FileThumbnail"
import FilePreview from "../../Upload/FilePreview"
import PatientListButtons from "./PatientListButtons"

import {
  formatPrice,
  accumulatePrice,
  generateDeliveryFee,
} from "../services/computations"
import { AppContext } from "../../../context/AppContext"

const PatientListTableRow = ({ patient, index }) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { patients } = epharmacy
  let oldFileNameArray = patient?.documents[0]?.oldname?.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  const handleRemovePatient = ({ index }) => {
    const patientList = [...patients]
    patientList.splice(index, 1)
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          patients: [...patientList],
        },
      },
    })
  }

  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(patient?.medicines) + generateDeliveryFee()
    ).toFixed(2),
  })

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: (
          <FilePreview
            file={patient?.documents[0]}
            isPdf={fileType === "pdf"}
          />
        ),
      },
    })

  return (
    <tr>
      <td>{patient?.patientName}</td>
      <td onClick={handleFileClick}>
        <FileThumbnail path={patient?.documents[0]?.path} />
      </td>
      <td className="has-text-right">P {orderSubtotal}</td>
      <td>
        <PatientListButtons
          index={index}
          handleRemovePatient={handleRemovePatient}
        />
      </td>
    </tr>
  )
}

export default PatientListTableRow
