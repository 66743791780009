import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image"

import styles from "../utils/epharmacy.module.scss"
import { isPdf } from "../../Upload/services/uploadDocuments"

const FileThumbnail = ({ path, isMobile }) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pdfThumbnailMobile: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed
  const pdfThumbnailMobile = data.pdfThumbnailMobile.childImageSharp.fixed

  if (isPdf(path))
    return (
      <Img
        fixed={isMobile ? pdfThumbnailMobile : pdfThumbnail}
        alt="pdfThumbnail"
      />
    )
  else
    return (
      <img
        className={classNames(
          "image",
          styles[`specialOrder__thumbnail${isMobile ? "Mobile" : ""}`]
        )}
        src={path}
        alt="attachment"
      />
    )
}

export default FileThumbnail
