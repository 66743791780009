import React from "react"

export const NoPatientsMessageMobile = ({ patients }) => {
  if (patients.length === 0)
    return (
      <div className="is-hidden-desktop is-hidden-tablet mt-2">
        <p className="is-size-6 has-text-grey has-text-centered">
          No patients added
        </p>
      </div>
    )
  return null
}

const NoPatientsMessage = ({ patients }) => {
  if (patients.length === 0)
    return (
      <div className="is-hidden-mobile">
        <p className="is-size-6 has-text-grey pl-1">No patients added</p>
        <hr className="mt-1 has-background-light" />
      </div>
    )
  return null
}

export default NoPatientsMessage
